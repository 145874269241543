//
//
//
//
//
//
//
//
//
//


export default {
  data: () => ({


  }),
  methods:{
    set(){

      this.$cookie.set('theme', this.$vuetify.theme.dark, 1000);
    }
  }
}
