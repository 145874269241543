//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LayoutBlank from './layouts/blank.vue';
import LayoutDashboard from './layouts/dashboardLayout.vue';


export default {
  name: 'App',
  components : {
LayoutBlank,
LayoutDashboard
  },
  methods : {
    get_notifications(){
    
  const _this = this
      this.api('get-notification').then(function(response) {

  console.log(response)

response.data.forEach((element,index) => {
response.data[index].user_seen = JSON.parse(response.data[index].user_seen)
})
console.log(response.data)

 _this.$store.state.new_notifications = response.data.filter(
      notif => !notif.user_seen.includes(parseInt(_this.$store.state.user_object['ID']) ) );


   _this.$store.state.notifications = response.data
  
      }).catch(function () {
  })
},
logout(){
this.$cookie.delete('session');
this.$router.go()
}
  },
  mounted: function () {
  if(this.$store.state.output == 'nahal'){
document.title = 'مدیریت تورهای نهال گشت'
  }else{
document.title = 'مدیریت تورهای سلام پرواز'
  }
    },
  created: function () {
    const _this = this


  if(this.$cookie.get('theme')){
if(this.$cookie.get('theme') == 'true'){
this.$vuetify.theme.dark = true
}else{
this.$vuetify.theme.dark = false
}

  }
  if(this.$cookie.get('session')){

      this.api('get-user').then(function(response) {
        _this.responsed = 1
  if(response.data.status == 'success'){
   _this.$store.state.user = response.data.data.display_name
   _this.$store.state.user_object = response.data.data
   _this.$store.state.user_object.user_id = response.data.data['ID']
   _this.get_notifications()

  // if (_this.$route.fullPath !== '/tours') _this.$router.push({ path: '/tours' })
setTimeout(function(){  _this.loading = false }, 1000);
  }
     
    

// _this.$store.state.user = []
      }).catch(function () {

        _this.responsed = -1
        setTimeout(function(){  }, 1000);
  
    _this.$router.push({ path: 'login' })
_this.$cookie.delete('session');
  })

  }else{
    this.responsed = 1
    this.$router.push({ path: 'login' })
        setTimeout(function(){  _this.loading = false }, 1000);
  }
  
  },
  computed : {
     resolveLayout: function () {
      if (this.$route.meta.layout === 'blank') return 'layout-blank'
      return 'layout-dashboard'
    }
  },
  data: () => ({
    responsed : 0,
    loading : true
  }),
};
