import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    output: "nahal",
    // api_url: "http://192.168.100.186/Nahalgasht/wp-json/v1/",
    // api_url: "http://localhost/wp-json/v1/",
    // api_url: "https://test.nahalgasht.com/wp-json/v1/",
    api_url: "https://nahalgasht.com/wp-json/v1/",
    nahal_access: ["1", "8", "824", "81", "818"],
    salam_access: ["1", "4", "6", "10", "13", "9", "19", "20", "22","23"],
    access: [],
    // output : 'salam',
    // api_url : 'https://salamparvaz.com/wp-json/v1/',
    exit: false,
    user: "",
    user_object: "",
    notifications: [],
    new_notifications: [],
  },
  mutations: {},
  actions: {},
  modules: {},
});
