//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
  },
}
